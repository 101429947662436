import React from 'react'
import { FormattedMessage } from 'react-intl'
import H2 from '../../theming/H2'
import PropertyCard from '../property-card'

export default ({ highlights = [], title }) => {
  if (highlights.length === 0) {
    return null
  }

  return (
    <div className="c-row c-row--beta c-row--bordered">
      <div className="o-container">
        <div className="o-grid o-grid--gutter">
          <div className="o-grid__item">
            <H2 className="section-title">{title}</H2>
          </div>
          <div className="o-grid__item">
            <div className="u-css-grid u-grid-cols-1-of-3-bp3 u-grid-gap">
              {highlights.map((property) => {
                return <PropertyCard key={property.id} property={property} />
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
