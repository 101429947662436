import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import PropertyHighlights from '../components/home/highlights'
import HomeMap from '../components/home/home-map'
import Header from '../components/header'
import SearchSection from '../components/search-section'
import Layout from '../components/layout'
import { useAgency } from '../hooks/use-agency'
import NoPropertiesYet from '../components/home/no-properties'
import { ImageHomeCover } from '../components/home/image-home-cover'
import { DefaultHomeCover } from '../components/home/default-home-cover'
import { VideoHomeCover } from '../components/home/video-home-cover'

export default (props) => {
  const { agency, settings } = useAgency()
  const { locale } = props.pageContext ?? agency.locale
  const { homepage_highlights, homepage_properties, homepage_cover } = settings
  const properties = props.data.allProperty.edges.map((e) => e.node)

  const highlightedProperties =
    homepage_properties === 'latest'
      ? props.data.latestProperties.nodes
      : homepage_highlights

  return (
    <Layout
      {...props}
      header={
        homepage_cover === 'borderless_image' ? (
          <div
            className="c-hero"
            style={{
              backgroundImage: `url("${settings.homepage_cover_image}")`,
            }}
          >
            <Header
              logoId={agency.photo?.data?.photo_file_id}
              siteTitle={agency.name}
              currentPath={props.location.pathname}
              borderless
            />
            <ImageHomeCover
              settings={settings}
              locale={locale}
              borderless
              className="c-row--hero"
            />
          </div>
        ) : undefined
      }
    >
      <div>
        {homepage_cover === 'image' && (
          <ImageHomeCover
            settings={settings}
            locale={locale}
            className="c-row--alpha c-row--collapsed"
          />
        )}
        {homepage_cover === 'default' && (
          <DefaultHomeCover settings={settings} locale={locale} />
        )}
        {homepage_cover === 'video' && settings.homepage_cover_video && (
          <VideoHomeCover settings={settings} locale={locale} />
        )}
        <PropertyHighlights
          highlights={highlightedProperties}
          title={
            settings[`new_properties_${locale}`] || (
              <FormattedMessage id="new-properties" />
            )
          }
        />
        {properties.length === 0 ? (
          <NoPropertiesYet />
        ) : (
          <HomeMap properties={properties} />
        )}
        <SearchSection />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($transactionTypes: [String!]!) {
    allProperty(filter: { is_preview: { ne: true } }) {
      edges {
        node {
          id
          attributes {
            location {
              geo {
                latitude
                longitude
              }
            }
          }
        }
      }
    }
    latestProperties: allProperty(
      limit: 3
      sort: { fields: published_at, order: DESC }
      filter: {
        project: { id: { eq: null } }
        negotiation: { in: $transactionTypes }
        is_preview: { ne: true }
      }
    ) {
      nodes {
        ...PropertyCard
      }
    }
  }
`
