import React from 'react'
import { FormattedMessage } from 'react-intl'

export function Tagline({ settings, locale }) {
  const tagline = settings[`tagline_${locale}`] || (
    <FormattedMessage id="cta.homepage" />
  )

  return <h1 className="big-type">{tagline}</h1>
}
