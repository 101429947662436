import React from 'react'
import { NegotiationToolbar } from './negotiation-toolbar'
import classNames from 'classnames'
import { Tagline } from './tagline'

export function ImageHomeCover({ settings, locale, borderless, className }) {
  return (
    <div className={classNames('c-row  c-row--centered', className)}>
      <div className="o-container">
        {!borderless && (
          <div className="o-grid__item u-pt-delta">
            <img src={settings.homepage_cover_image} />
          </div>
        )}
        <section className="o-grid o-grid--gutter">
          <div
            className={classNames('o-grid__item', {
              'o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3':
                settings.transaction_types === 'let_and_sale',
              'u-2-of-6-bp3 u-push-2-of-6-bp3':
                settings.transaction_types !== 'let_and_sale',
            })}
          >
            <Tagline settings={settings} locale={locale} />
            <NegotiationToolbar />
          </div>
        </section>
      </div>
    </div>
  )
}
