import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import Link from '../localized-link'
import { useAgency } from '../../hooks/use-agency'

export function NegotiationToolbar() {
  const { settings } = useAgency()
  const { transaction_types } = settings

  return (
    <p className="toolbar">
      {transaction_types !== 'let' && (
        <Link
          to="/sale"
          className={classNames('c-button c-button--alpha c-button--md', {
            'c-button--half': transaction_types == 'let_and_sale',
            'c-button--block': transaction_types == 'sale',
          })}
        >
          <FormattedMessage id="buy" />
        </Link>
      )}
      {transaction_types !== 'sale' && (
        <Link
          to="/let"
          className={classNames('c-button c-button--alpha c-button--md', {
            'c-button--half': transaction_types == 'let_and_sale',
            'c-button--block': transaction_types == 'let',
          })}
        >
          <FormattedMessage id="rent" />
        </Link>
      )}
    </p>
  )
}
