import React from 'react'
import classNames from 'classnames'
import { NegotiationToolbar } from './negotiation-toolbar'
import { Tagline } from './tagline'

export function DefaultHomeCover({ settings, locale }) {
  return (
    <div className="c-row c-row--alpha c-row--collapsed c-row--centered">
      <div className="o-container">
        <div className="o-grid o-grid--gutter">
          <div
            className={classNames('o-grid__item', {
              'u-3-of-5-bp3 u-push-1-of-5-bp3':
                settings.transaction_types === 'let_and_sale',
              'u-2-of-6-bp3 u-push-2-of-6-bp3':
                settings.transaction_types !== 'let_and_sale',
            })}
          >
            <Tagline settings={settings} locale={locale} />
            <Ilustration />
            <NegotiationToolbar />
          </div>
        </div>
      </div>
    </div>
  )
}

const Ilustration = () => {
  return (
    <svg
      width={308}
      height={27}
      viewBox="32 156 308 27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient x1="0%" y1="50%" y2="50%" id="a">
          <stop stopColor="#47C9AD" offset="0%" />
          <stop stopColor="#47B9C9" offset="25.899%" />
          <stop stopColor="#17A8E6" offset="50.616%" />
          <stop stopColor="#ED2D98" offset="75.109%" />
          <stop stopColor="#ED0058" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(32 156)">
        <g fill="#47C9AD">
          <path d="M34.5 26H34V10.5a.5.5 0 0 0-.5-.5H29v-.5a.5.5 0 1 0-1 0v.5h-1v-.5a.5.5 0 1 0-1 0v.5h-.5a.5.5 0 0 0-.5.5V26h-2V4.5a.5.5 0 0 0-.5-.5H20v-.5a.5.5 0 1 0-1 0V4h-1v-.5a.5.5 0 0 0-1 0V4h-1v-.5a.5.5 0 0 0-1 0V4h-4.5a.5.5 0 0 0-.5.5V26h-.5a.5.5 0 1 0 0 1h25a.5.5 0 1 0 0-1zM15 26v-3h3v3h-3zm4 0v-3.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5V26h-3V5h11v21h-3zm10 0v-3h1v3h-1zm2 0v-3.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5V26h-2V11h7v15h-2z" />
          <path d="M20.5 10h-8a.5.5 0 0 0 0 1h8a.5.5 0 1 0 0-1zM20.5 12h-8a.5.5 0 0 0 0 1h8a.5.5 0 1 0 0-1zM20.5 14h-8a.5.5 0 1 0 0 1h8a.5.5 0 1 0 0-1zM20.5 16h-8a.5.5 0 1 0 0 1h8a.5.5 0 1 0 0-1zM20.5 18h-8a.5.5 0 1 0 0 1h8a.5.5 0 1 0 0-1zM20.5 20h-8a.5.5 0 1 0 0 1h8a.5.5 0 1 0 0-1zM31.5 14h-4a.5.5 0 1 0 0 1h4a.5.5 0 1 0 0-1zM31.5 12h-4a.5.5 0 1 0 0 1h4a.5.5 0 1 0 0-1zM31.5 16h-4a.5.5 0 1 0 0 1h4a.5.5 0 1 0 0-1zM31.5 18h-4a.5.5 0 1 0 0 1h4a.5.5 0 1 0 0-1zM31.5 20h-4a.5.5 0 1 0 0 1h4a.5.5 0 1 0 0-1zM20.5 8h-8a.5.5 0 0 0 0 1h8a.5.5 0 1 0 0-1zM20.5 6h-8a.5.5 0 0 0 0 1h8a.5.5 0 1 0 0-1z" />
        </g>
        <g fill="#47B9C9">
          <path d="M89 17c1.379 0 2.5-1.122 2.5-2.5S90.379 12 89 12a2.503 2.503 0 0 0-2.5 2.5c0 1.378 1.122 2.5 2.5 2.5zm0-4c.827 0 1.5.673 1.5 1.5S89.827 16 89 16s-1.5-.673-1.5-1.5.673-1.5 1.5-1.5z" />
          <path d="M100.5 26H99V14.904c.165.058.332.096.5.096a1.5 1.5 0 0 0 .644-2.855l-10.5-5a1.502 1.502 0 0 0-1.29 0l-10.5 5a1.5 1.5 0 0 0-.71 2 1.493 1.493 0 0 0 1.854.761V26H77.5a.5.5 0 0 0 0 1h23c.275 0 .5-.225.5-.5s-.225-.5-.5-.5zM78.049 13.714a.501.501 0 0 1 .236-.667l10.5-5a.506.506 0 0 1 .43 0l10.5 5A.5.5 0 0 1 99.5 14a.517.517 0 0 1-.215-.048L89 9.053l-10.285 4.898a.501.501 0 0 1-.666-.237zm1.951.733l9-4.286 9 4.286V26H88v-7.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0-.5.5V26h-2V14.447zM83 26v-7h4v7h-4z" />
          <path d="M90 18.5v4c0 .275.225.5.5.5h5c.275 0 .5-.225.5-.5v-4c0-.275-.225-.5-.5-.5h-5c-.275 0-.5.225-.5.5zm1 .5h4v3h-4v-3z" />
        </g>
        <path
          d="M163.998 14.45a.5.5 0 0 0-.497-.45h-.5v-.5c0-1.93-1.57-3.5-3.5-3.5a3.47 3.47 0 0 0-1.402.298l-.101-.856A.5.5 0 0 0 157.5 9H155V7.5c0-2.481-2.02-4.5-4.5-4.5a4.505 4.505 0 0 0-4.5 4.5V9h-2.5a.501.501 0 0 0-.497.441l-2 17a.5.5 0 0 0 .497.559h18a.505.505 0 0 0 .374-.168.494.494 0 0 0 .122-.391l-.17-1.441h4.674a.497.497 0 0 0 .497-.55l-1-10zM162 13.5v.5h-5v-.5c0-1.378 1.12-2.5 2.5-2.5 1.379 0 2.5 1.122 2.5 2.5zm-15-6c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5V9h-7V7.5zM158.939 26h-16.876l1.883-16H146v1.092c-.581.207-1 .757-1 1.408 0 .827.673 1.5 1.5 1.5s1.5-.673 1.5-1.5c0-.651-.42-1.201-1-1.408V10h7v1.092c-.581.207-1 .757-1 1.408 0 .827.673 1.5 1.5 1.5s1.5-.673 1.5-1.5c0-.651-.42-1.201-1-1.408V10h2.056l.106.914A3.48 3.48 0 0 0 156 13.5v.5h-.5a.5.5 0 0 0-.497.45l-1 10a.498.498 0 0 0 .497.55h4.32l.118 1zM147 12.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm8 0a.5.5 0 1 1-1.002-.002.5.5 0 0 1 1.002.002zm.053 11.5l.899-9h7.096l.899 9h-8.894z"
          fill="#17A8E6"
        />
        <path
          d="M236.5 16H224v-3h6.5a.5.5 0 0 0 .5-.5V.5a.5.5 0 0 0-.5-.5h-17a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h6.53v3H207.5a.5.5 0 0 0 0 1h2.5v9.5a.5.5 0 0 0 1 0V17h16v9.5a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5V17h2.5a.5.5 0 0 0 0-1zM230 1v9h-16V1h16zm-16 10h16v1h-16v-1zm7 2h2v3h-2v-3zm12 9h-5v-2h5v2zm-5 1h5v3h-5v-3zm5-4h-5v-2h5v2z"
          fill="#ED2D98"
        />
        <g transform="translate(272 3)" fill="#ED0058">
          <path d="M1.91 5.934L12 3.05l10.091 2.883a1.493 1.493 0 0 0 1.843-1.024 1.49 1.49 0 0 0-1.024-1.843l-10.5-3a1.5 1.5 0 0 0-.819 0l-10.5 3a1.492 1.492 0 0 0 .819 2.868zm-.547-1.912l10.5-3a.506.506 0 0 1 .273 0l10.5 3a.497.497 0 1 1-.274.956L12 2.017 1.637 4.978a.5.5 0 0 1-.615-.341.499.499 0 0 1 .341-.615zM17.5 22h3a.5.5 0 0 0 .5-.5V19h1.5a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5h-2.64l-1.886-5.658A.5.5 0 0 0 17.5 7h-11a.5.5 0 0 0-.474.342L4.14 13H1.5a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5H3v2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V19h10v2.5a.5.5 0 0 0 .5.5zm-11-4a.5.5 0 0 0-.5.5V21H4v-2.5a.5.5 0 0 0-.5-.5H2v-4h2.5a.5.5 0 0 0 .474-.342L6.86 8h10.28l1.886 5.658A.5.5 0 0 0 19.5 14H22v4h-1.5a.5.5 0 0 0-.5.5V21h-2v-2.5a.5.5 0 0 0-.5-.5h-11z" />
          <circle cx={5} cy={16} r=".994" />
          <circle cx={19} cy={16} r=".994" />
          <path d="M23.5 23H.5a.5.5 0 0 0 0 1h23a.5.5 0 0 0 0-1zM17.699 13.36l-1.16-4a.5.5 0 0 0-.48-.36H7.941a.5.5 0 0 0-.48.361l-1.16 4a.502.502 0 0 0 .48.639h10.438a.498.498 0 0 0 .48-.64zM7.447 13l.87-3h7.366l.87 3H7.447z" />
        </g>
        <rect fill="url(#a)" y={25} width={308} height={2} rx={2} />
      </g>
    </svg>
  )
}
